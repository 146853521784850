import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import DataTable from './pages/DataTable';
import Servis from './pages/Servis';
import { UserProvider, useUser } from './components/UserContext';
import Navbar from './components/Navbar';

const AppContent = () => {
  const { user } = useUser(); // user nesnesi üzerinden veriler alınıyor

  return (
    <div>
      {user.userId && <Navbar />} {/* Kullanıcı oturum açmışsa Navbar görünür */}
      <div className={`${user.userId ? 'pt-16' : ''}`}> {/* Navbar yüksekliği kadar padding ekleniyor */}
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/DataTable" element={<DataTable />} />
          <Route path="/servis" element={<Servis />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </div>
  );
};

function App() {
  return (
    <UserProvider>
      <Router>
        <AppContent />
      </Router>
    </UserProvider>
  );
}

export default App;