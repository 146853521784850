import React, { useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import Accordion from "../components/Accordion";
import ComboBox from "../components/ComboBox";
import PhoneServiceRecords from "../components/PhoneServiceRecords";
import ComputerServiceRecords from "../components/ComputerServiceRecords";
import DeviceDetails from "../components/DeviceDetails"; // Bu gerekebilir

const cihaz_turleri = ['Cep Telefonu', 'Tablet', 'Dizüstü Bilgisayar', 'Masaüstü Bilgisayar', 'Akıllı Saat'];

const Servis = () => {
    const [selectedDeviceType, setSelectedDeviceType] = useState('');
    const [selectedModelDetails, setSelectedModelDetails] = useState(null);

    const handleSelect = (deviceType) => {
        setSelectedDeviceType(deviceType);
    };

    const handleModelSelect = (modelDetails) => {
        setSelectedModelDetails(modelDetails);
    };

    const renderServiceComponent = () => {
        if (selectedDeviceType === 'Cep Telefonu') {
            return <PhoneServiceRecords selectedModelDetails={selectedModelDetails} />;
        }
        if (selectedDeviceType === 'Dizüstü Bilgisayar') {
            return <ComputerServiceRecords selectedModelDetails={selectedModelDetails} />;
        }

        // Diğer cihazlar için şu an bir şey render edilmeyecek
        return <div>Yapım aşamasında...</div>;
    };

    return (
        <PrimeReactProvider>
            <Splitter style={{ height: '100vh' }}>
                <SplitterPanel className="p-4" size={20}>
                    <div>
                        <ComboBox options={cihaz_turleri} onSelect={handleSelect} />
                    </div>
                    <div>
                        <Accordion deviceType={selectedDeviceType} onModelSelect={handleModelSelect} />
                    </div>
                </SplitterPanel>
                <SplitterPanel className="p-4" size={80}>
                    <div className="flex flex-col h-full">
                        {renderServiceComponent()}
                    </div>
                </SplitterPanel>
            </Splitter>
        </PrimeReactProvider>
    );
};

export default Servis;
