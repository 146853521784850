import React, { useRef, useState, useEffect } from "react";
import './PatternLock.css';

const PatternLock = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawnPattern, setDrawnPattern] = useState([]);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    const container = containerRef.current;

    const handleMouseDown = (e) => {
      clearCanvasAndReset(ctx);
      if (e.target.classList.contains("dot")) {
        setIsDrawing(true);
        addDotToPattern(e.target);
        const { left, top, width, height } = e.target.getBoundingClientRect();
        setLastPosition({
          x: left + width / 2 - container.getBoundingClientRect().left,
          y: top + height / 2 - container.getBoundingClientRect().top,
        });
      }
    };

    const handleMouseMove = (e) => {
      if (isDrawing) {
        if (!e.target.classList.contains("dot")) {
          drawDynamicLine(ctx, lastPosition.x, lastPosition.y, e.offsetX, e.offsetY);
        } else {
          ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          redrawPermanentLines(ctx);
        }
      }
    };

    const handleMouseUp = () => {
      setIsDrawing(false);
      redrawPermanentLines(ctx);
      validateDrawing();
    };

    const handleMouseOver = (e) => {
      if (isDrawing && e.target.classList.contains("dot")) {
        const dataId = e.target.getAttribute("data-id");
        if (!drawnPattern.includes(dataId)) {
          addDotToPattern(e.target);
          const { left, top, width, height } = e.target.getBoundingClientRect();
          drawPermanentLine(
            ctx,
            lastPosition.x,
            lastPosition.y,
            left + width / 2 - container.getBoundingClientRect().left,
            top + height / 2 - container.getBoundingClientRect().top
          );
          setLastPosition({
            x: left + width / 2 - container.getBoundingClientRect().left,
            y: top + height / 2 - container.getBoundingClientRect().top,
          });
        }
      }
    };

    container.addEventListener("mousedown", handleMouseDown);
    container.addEventListener("mousemove", handleMouseMove);
    container.addEventListener("mouseup", handleMouseUp);
    container.addEventListener("mouseover", handleMouseOver);

    return () => {
      container.removeEventListener("mousedown", handleMouseDown);
      container.removeEventListener("mousemove", handleMouseMove);
      container.removeEventListener("mouseup", handleMouseUp);
      container.removeEventListener("mouseover", handleMouseOver);
    };
  }, [isDrawing, drawnPattern, lastPosition]);

  const addDotToPattern = (dot) => {
    dot.classList.add("active");
    setDrawnPattern((prevPattern) => [...prevPattern, dot.getAttribute("data-id")]);
  };

  const drawDynamicLine = (ctx, x1, y1, x2, y2) => {
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    redrawPermanentLines(ctx);
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.strokeStyle = "#24354e";
    ctx.lineWidth = 5;
    ctx.lineCap = "round";
    ctx.stroke();
  };

  const drawPermanentLine = (ctx, x1, y1, x2, y2) => {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  };

  const redrawPermanentLines = (ctx) => {
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    for (let i = 1; i < drawnPattern.length; i++) {
      const start = document.querySelector(`.dot[data-id="${drawnPattern[i - 1]}"]`).getBoundingClientRect();
      const end = document.querySelector(`.dot[data-id="${drawnPattern[i]}"]`).getBoundingClientRect();
      drawPermanentLine(
        ctx,
        start.left + start.width / 2 - containerRef.current.getBoundingClientRect().left,
        start.top + start.height / 2 - containerRef.current.getBoundingClientRect().top,
        end.left + end.width / 2 - containerRef.current.getBoundingClientRect().left,
        end.top + end.height / 2 - containerRef.current.getBoundingClientRect().top
      );
    }
  };

  const clearCanvasAndReset = (ctx) => {
    if (timeoutId) clearTimeout(timeoutId);
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setDrawnPattern([]);
    Array.from(containerRef.current.getElementsByClassName("dot")).forEach((dot) => dot.classList.remove("active"));
  };

  const validateDrawing = () => {
    if (drawnPattern.length < 3) {
      const id = setTimeout(() => {
        clearCanvasAndReset(canvasRef.current.getContext("2d"));
      }, 1000);
      setTimeoutId(id);
    }
  };

  return (
    <div style={{ position: "relative", width: "160px", height: "160px" }}>
      <div id="pattern-container" ref={containerRef} style={{ width: "160px", height: "160px" }}>
        {Array.from({ length: 9 }).map((_, i) => (
          <div className="dot" key={i + 1} data-id={i + 1}></div>
        ))}
      </div>
      <canvas
        id="pattern-canvas"
        ref={canvasRef}
        width="160"
        height="160"
        style={{ position: "absolute", top: 0, left: 0, pointerEvents: "none" }}
      ></canvas>
    </div>
  );
};

export default PatternLock;
