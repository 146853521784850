import Validator from './Validator';

class UsernameValidator extends Validator {
  async validate(inputElement, username) {
    this.clearError(inputElement, { borderColor: 'border-red-500', iconClass: 'username-error-icon' });

    let errors = [];
    if (!username) {
      errors.push(this.setError(inputElement, 'Kullanıcı adı boş olamaz.', { showIcon: true, borderColor: 'border-red-500', iconClass: 'username-error-icon' }));
      return errors; // Boşsa diğer kontrolleri yapma
    }

    username = username.toLowerCase();

    const turkishCharacters = /[çÇğĞıİöÖşŞüÜ]/;
    const allowedCharacters = /^[a-zA-Z0-9._-]+$/;


    if (turkishCharacters.test(username)) {
      errors.push(this.setError(inputElement, 'Kullanıcı adında Türkçe karakter kullanılamaz.', { showIcon: true, borderColor: 'border-red-500', iconClass: 'username-error-icon' }));
    }

    if (username.length < 3) {
      errors.push(this.setError(inputElement, 'Kullanıcı adı en az 3 karakter olmalıdır.', { showIcon: true, borderColor: 'border-red-500', iconClass: 'username-error-icon' }));
    }


    // Türkçe karakterler çıkarıldıktan sonra izin verilen karakter kontrolü yap
    const sanitizedUsername = username.replace(turkishCharacters, '');
    if (!allowedCharacters.test(sanitizedUsername) && !turkishCharacters.test(username)) { //Sadece izinsiz karakter hatası varsa buraya girecek. türkçe karakter varsa buraya girmesin
      errors.push(this.setError(inputElement, 'Kullanıcı adında yalnızca harfler, sayılar, "_" "-" ve "." kullanılabilir.', { showIcon: true, borderColor: 'border-red-500', iconClass: 'username-error-icon' }));
    } else if (!allowedCharacters.test(sanitizedUsername) && turkishCharacters.test(username)) { // türkçe karakter ve izinsiz karakter hatası varsa buraya girecek
      errors.push(this.setError(inputElement, 'Kullanıcı adında yalnızca harfler, sayılar, "_" "-" ve "." kullanılabilir. Ayrıca Türkçe karakterler içermemelidir', { showIcon: true, borderColor: 'border-red-500', iconClass: 'username-error-icon' }));
    }




    if (errors.length === 0) {
      const availability = await this.checkUsernameAvailability(username);
      if (!availability.available) {
        errors.push(this.setError(inputElement, availability.message, { showIcon: true, borderColor: 'border-red-500', iconClass: 'username-error-icon' }));
      }
    }

    if (errors.length === 0) {
      this.setSuccess(inputElement);
    }

    return errors;
  }

  setSuccess(inputElement) {
    inputElement.classList.remove('border-red-500');
    inputElement.classList.add('border-green-500');
  }

  async checkUsernameAvailability(username) {
    try {
      const response = await fetch('/api/checkAvailability', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error checking username availability:', error);
      return { available: false, message: 'Username availability check failed' };
    }
  }
}

export default UsernameValidator;