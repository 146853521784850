import React from "react";
import DeviceDetails from "../components/DeviceDetails";
import PatternLock from "../components/PatternLock";
import { Card } from 'primereact/card';
import { PrimeReactProvider } from "primereact/api";
import { Splitter, SplitterPanel } from 'primereact/splitter';

const PhoneServiceRecords = ({ selectedModelDetails }) => {
    return (
        <PrimeReactProvider>
            <div style={{ height: '100%' }}>
                <Splitter layout="vertical" style={{ height: '100%' }}>
                    <SplitterPanel size={30} className="flex flex-col">
                        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DeviceDetails modelDetails={selectedModelDetails} />
                            </div>
                        </Card>
                    </SplitterPanel>
                    <SplitterPanel size={70}>
                        <div style={{ padding: '1rem' }}>
                            <PatternLock
                                style={{ height: "calc(3 * 3rem + 2 * 1rem)", width: "100%" }}
                            />
                        </div>
                    </SplitterPanel>
                </Splitter>
            </div>
        </PrimeReactProvider>
    );
};

export default PhoneServiceRecords;