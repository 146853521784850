import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { useUser } from '../components/UserContext';

const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useUser();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let email = usernameOrEmail;
      if (!usernameOrEmail.includes('@')) {
        const response = await fetch('api/getEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: usernameOrEmail }),
        });
        const data = await response.json();
        if (!data.email) {
          throw new Error('Geçersiz kullanıcı adı');
        }
        email = data.email;
      }

      // Firebase kimlik doğrulama
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const firebaseUid = userCredential.user.uid;

      const userInfoResponse = await fetch('/api/getUserInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firebase_uid: firebaseUid }),
      });
      const userInfoData = await userInfoResponse.json();

      // UserContext güncelleme
      setUser({
        userId: userInfoData.kullanıcı_id,
        işletmeAdı: userInfoData.işletme_adı, // İşletme adını UserContext'e ekliyoruz
      });

      navigate('/DataTable');

    } catch (error) {
      console.error('Login error:', error);
      setError('Giriş başarısız oldu. Lütfen bilgilerinizi kontrol edin.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">Giriş yap</h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700">Kullanıcı adı veya Email</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter your username or email"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Parola</label>
            <input
              type="password"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
          >
            Login
          </button>
        </form>

        <p className="text-center text-gray-600 mt-4">
          Hesabınız yok mu?{' '}
          <Link to="/register" className="text-blue-500 hover:underline">
            Kayıt Ol
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;