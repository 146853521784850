import React from "react";

const ComputerServiceRecords = () => {
    return (
        <div>
            <h2>Bilgisayar Servis Kayıtları</h2>
            <p>Burada bilgisayar için servis kaydı bilgileri yer alacak.</p>
        </div>
    );
};

export default ComputerServiceRecords;
