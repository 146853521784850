import Validator from './Validator';

class EmailValidator extends Validator {
  async validate(inputElement, email) {
    // clearError işlevi ile hata sınır rengini ve ikonu temizle
    this.clearError(inputElement, {
      borderColor: 'border-red-500', // Temizlenecek hata kenarlık rengi
      iconClass: 'email-error-icon', // Temizlenecek hata ikonu sınıfı
    });

    let errors = [];

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      errors.push(this.setError(inputElement, 'E-posta boş olamaz.', {
        showIcon: true,
        borderColor: 'border-red-500',
        iconClass: 'email-error-icon',
      }));
    } else if (!emailPattern.test(email)) {
      errors.push(this.setError(inputElement, 'Geçersiz e-posta adresi.', {
        showIcon: true,
        borderColor: 'border-red-500',
        iconClass: 'email-error-icon',
      }));
    } else {
      const availability = await this.checkEmailAvailability(email);
      if (!availability.available) {
        errors.push(this.setError(inputElement, availability.message, {
          showIcon: true,
          borderColor: 'border-red-500',
          iconClass: 'email-error-icon',
        }));
      }
    }

    if (errors.length === 0) {
      this.setSuccess(inputElement);
    }

    return errors;
  }

  setSuccess(inputElement) {
    inputElement.classList.remove('border-red-500');
    inputElement.classList.add('border-green-500');
  }

  async checkEmailAvailability(email) {
    try {
      const response = await fetch('/api/checkAvailability', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error checking email availability:', error);
      return { available: false, message: 'Email availability check failed' };
    }
  }
}

export default EmailValidator;