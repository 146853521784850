import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useUser } from '../components/UserContext';

const Navbar = () => {
  const { user, clearUser } = useUser(); // user ve clearUser fonksiyonları kullanılıyor

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        clearUser(); // clearUser fonksiyonu ile tüm kullanıcı bilgileri temizlenir
        window.location.href = '/login'; // Çıkış sonrası yönlendirme
      })
      .catch(error => {
        console.error("Logout error:", error);
      });
  };

  return (
    <nav className="bg-gradient-to-r from-blue-500 to-indigo-500 h-16 p-4 shadow-lg fixed top-0 w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-2xl font-bold tracking-wider hover:scale-105 transform transition-all duration-300">
          <Link to="/home">{user.işletmeAdı || "İşletme Adı"}</Link> {/* İşletme adını göster */}
        </div>
        <div className="flex space-x-6">
          <Link
            to="/DataTable"
            className="text-white text-lg font-medium hover:text-indigo-200 hover:underline transform transition-all duration-200">
            Home
          </Link>
          <Link
            to="/servis"
            className="text-white text-lg font-medium hover:text-indigo-200 hover:underline transform transition-all duration-200">
            Servis
          </Link>
          <button
            onClick={handleLogout}
            className="text-white text-lg font-medium hover:text-red-300 hover:underline transform transition-all duration-200">
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;