import React, { useState, useRef, useEffect } from 'react';
import './ComboBox.css';

const ComboBox = ({ options, onSelect }) => {
    const [active, setActive] = useState(false);
    const [selected, setSelected] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActive(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setActive(!active);
    };

    const selectOption = (option) => {
        setSelected(option);
        setActive(false);
        if (onSelect) {
            onSelect(option); // Seçilen seçeneği gönder
        }
    };

    return (
        <div className={`comboBox ${active ? 'active' : ''}`} ref={dropdownRef} onClick={toggleDropdown}>
            <input type="text" className="textBox" placeholder="Cihaz tipi" readOnly value={selected} />
            <a className={`cb-arrow-icon ${active ? 'open' : ''}`}>
                <span className="left-bar"></span>
                <span className="right-bar"></span>
            </a>
            {active && (
                <div className="option">
                    {options.map(option => (
                        <div key={option} onClick={() => selectOption(option)}>{option}</div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ComboBox;