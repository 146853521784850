import Validator from './Validator';

class PasswordValidator extends Validator {
  validate(passwordElement, password, confirmPasswordElement, confirmPassword) {
    // Parola alanı için hataları temizle
    this.clearError(passwordElement, {
      borderColor: 'border-red-500',
      iconClass: 'password-error-icon',
    });

    // Parola tekrar alanı için hataları temizle
    if (confirmPasswordElement) {
      this.clearError(confirmPasswordElement, {
        borderColor: 'border-red-500',
        iconClass: 'password-error-icon',
      });
    }

    let errors = [];

    // Parola kurallarını kontrol et
    if (!password) {
      errors.push(this.setError(passwordElement, 'Parola boş olamaz.', {
        showIcon: true,
        borderColor: 'border-red-500',
        iconClass: 'password-error-icon',
      }));
    } else {
      if (password.length < 8) {
        errors.push(this.setError(passwordElement, 'Parola en az 8 karakter olmalıdır.', {
          showIcon: true,
          borderColor: 'border-red-500',
          iconClass: 'password-error-icon',
        }));
      }
      if (!/[A-Z]/.test(password)) {
        errors.push(this.setError(passwordElement, 'Parola en az bir büyük harf içermelidir.', {
          showIcon: true,
          borderColor: 'border-red-500',
          iconClass: 'password-error-icon',
        }));
      }
      if (!/[a-z]/.test(password)) {
        errors.push(this.setError(passwordElement, 'Parola en az bir küçük harf içermelidir.', {
          showIcon: true,
          borderColor: 'border-red-500',
          iconClass: 'password-error-icon',
        }));
      }
      if (!/[0-9]/.test(password)) {
        errors.push(this.setError(passwordElement, 'Parola en az bir rakam içermelidir.', {
          showIcon: true,
          borderColor: 'border-red-500',
          iconClass: 'password-error-icon',
        }));
      }
    }

    // Parola tekrarı varsa kontrol et
    if (errors.length === 0 && confirmPasswordElement && confirmPassword !== undefined) {
      if (!confirmPassword) {
        errors.push(this.setError(confirmPasswordElement, 'Parola tekrarı boş olamaz.', {
          showIcon: true,
          borderColor: 'border-red-500',
          iconClass: 'password-error-icon',
        }));
      } else if (password !== confirmPassword) {
        // Parola ve parola tekrarı uyuşmuyorsa her iki alana da hata ekle
        const errorMessage = 'Parola ve parola tekrarı uyuşmuyor.';
        errors.push(this.setError(passwordElement, errorMessage, {
          showIcon: true,
          borderColor: 'border-red-500',
          iconClass: 'password-error-icon',
        }));
        errors.push(this.setError(confirmPasswordElement, errorMessage, {
          showIcon: true,
          borderColor: 'border-red-500',
          iconClass: 'password-error-icon',
        }));
      }
    }

    // Eğer tüm kurallar sağlanıyorsa, yeşil sınırı yalnızca password alanına ekleyin
    if (errors.length === 0) {
      this.setSuccess(passwordElement);
      if (confirmPasswordElement) {
        this.setSuccess(confirmPasswordElement);
      }
    }

    return errors;
  }

  setSuccess(inputElement) {
    inputElement.classList.remove('border-red-500');
    inputElement.classList.add('border-green-500');
  }
}

export default PasswordValidator;