import Validator from './Validator';

class BusinessNameValidator extends Validator {
    async validate(inputElement, businessName) {
        this.clearError(inputElement, { borderColor: 'border-red-500', iconClass: 'business-name-error-icon' });

        const errors = [];

        if (!businessName) {
            errors.push(this.setError(inputElement, 'İşletme adı boş olamaz.', { showIcon: true, borderColor: 'border-red-500', iconClass: 'business-name-error-icon' }));
            return errors;
        }

        const minLength = 3;
        const maxLength = 50;
        const allowedCharacters = /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s0-9]+$/;

        if (businessName.length < minLength) {
            errors.push(this.setError(inputElement, `İşletme adı en az ${minLength} karakter olmalıdır.`, { showIcon: true, borderColor: 'border-red-500', iconClass: 'business-name-error-icon' }));
        }

        if (businessName.length > maxLength) {
            errors.push(this.setError(inputElement, `İşletme adı en fazla ${maxLength} karakter olabilir.`, { showIcon: true, borderColor: 'border-red-500', iconClass: 'business-name-error-icon' }));
        }

        if (!allowedCharacters.test(businessName)) {
            errors.push(this.setError(inputElement, 'İşletme adında izin verilmeyen karakterler içeriyor.', { showIcon: true, borderColor: 'border-red-500', iconClass: 'business-name-error-icon' }));
        }

        if (errors.length === 0) {
            this.setSuccess(inputElement);
        }

        return errors;
    }

    setSuccess(inputElement) {
        inputElement.classList.remove('border-red-500');
        inputElement.classList.add('border-green-500');
    }
}

export default BusinessNameValidator;