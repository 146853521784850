import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import BusinessNameValidator from '../components/BusinessNameValidator';
import UsernameValidator from '../components/UsernameValidator';
import EmailValidator from '../components/EmailValidator';
import PasswordValidator from '../components/PasswordValidator';
import RecaptchaValidator from '../components/RecaptchaValidator';
import './loader-animation.css';
import Popup from '../components/Popup';

const Register = () => {
  const [businessName, setBusinessName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch('api/sehirler');
        const data = await response.json();
        setCities(data);
      } catch (error) {
        console.error('Şehirleri listeleme hatası:', error);
      }
    };

    fetchCities();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!businessName && !username && !email && !password) {
      setErrors(['Lütfen tüm gerekli alanları doldurunuz.']);
      setLoading(false);
      return;
    }

    const businessNameInputElement = document.getElementById('businessName');
    const usernameInputElement = document.getElementById('username');
    const emailInputElement = document.getElementById('email');
    const passwordInputElement = document.getElementById('password');
    const confirmPasswordInputElement = document.getElementById('confirmPassword');

    const businessNameValidator = new BusinessNameValidator();
    const usernameValidator = new UsernameValidator();
    const emailValidator = new EmailValidator();
    const passwordValidator = new PasswordValidator();
    const recaptchaValidator = new RecaptchaValidator();

    const businessNameErrors = await businessNameValidator.validate(businessNameInputElement, businessName);
    const usernameErrors = await usernameValidator.validate(usernameInputElement, username);
    const emailErrors = await emailValidator.validate(emailInputElement, email);
    const passwordErrors = passwordValidator.validate(
      passwordInputElement,
      password,
      confirmPasswordInputElement,
      confirmPassword
    );
    const recaptchaErrors = await recaptchaValidator.validate(recaptchaToken); // recaptchaToken doğrulaması

    const newErrors = [];
    if (businessNameErrors.length > 0) {
      newErrors.push(...businessNameErrors);
    }
    if (usernameErrors.length > 0) {
      newErrors.push(...usernameErrors);
    }
    if (emailErrors.length > 0) {
      newErrors.push(...emailErrors);
    }
    if (passwordErrors.length > 0) {
      newErrors.push(...passwordErrors);
    }
    if (recaptchaErrors.length > 0) {
      newErrors.push(...recaptchaErrors);
    }

    setErrors(newErrors);

    if (newErrors.length === 0) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        const firebaseUid = user.uid;

        const response = await fetch('/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            kullanıcı_adı: username,
            email: email,
            işletme_adı: businessName,
            şehir_id: city,
            firebase_uid: firebaseUid,
          }),
        });

        if (response.ok) {
          // Kayıt başarılı
          setLoading(false);
          setShowPopup(true);
        } else {
          const errorData = await response.json();
          console.error("Kayıt hatası:", errorData.error);
          setErrors(["Kayıt sırasında bir hata oluştu."]);
        }
      } catch (error) {
        setErrors(["Firebase hatası: " + error.message]);
        setLoading(false);
      }
    }
    else {
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    navigate('/login');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {loading && ( // loading durumunda overlay ve animasyon gösterilecek
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
          <div className="lds-dual-ring"></div>
        </div>
      )}
      {showPopup && (
        <Popup message="Kullanıcı başarılı bir şekilde oluşturuldu!" onClose={handleClosePopup} />
      )}
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Kayıt Ol</h2>
        {errors.length > 0 && (
          <div className="mb-4">
            <ul className="list-disc list-inside text-sm">
              {errors.map((error, index) => (
                <li key={index} className="text-red-500">{error}</li>
              ))}
            </ul>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="relative flex items-center mb-4">
            <input
              type="text"
              id="businessName"
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              placeholder="İşletme adınızı girin"
              value={businessName}
              onChange={e => setBusinessName(e.target.value)}
            />
          </div>
          <div className="relative flex items-center mb-4">
            <input
              type="text"
              id="username"
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              placeholder="Bir kullanıcı adı belirleyin"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="relative flex items-center mb-4">
            <input
              type="email"
              id="email"
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              placeholder="E-posta adresinizi girin"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="relative flex items-center mb-4">
            <input
              type="password"
              id="password"
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              placeholder="Parola oluşturun"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className="relative flex items-center mb-6">
            <input
              type="password"
              id="confirmPassword"
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              placeholder="Parolanızı tekrar girin"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="relative flex items-center mb-6">
            <select
              id="city"
              className="w-2/4 border border-gray-300 p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              value={city}
              onChange={e => setCity(e.target.value)}
            >
              <option value="">Şehir seçin</option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.şehir_adı}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6">
            <ReCAPTCHA
              sitekey="6Lf-m20qAAAAANROj6bWsU0s-heThO6NeUens7G8"
              onChange={(token) => setRecaptchaToken(token)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          >
            Kayıt Ol
          </button>
        </form>
        <p className="mt-6 text-center text-gray-600">
          Zaten bir hesabınız var mı?{' '}
          <Link to="/login" className="text-blue-500 hover:underline">Giriş yapın</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;