import React, { useState, useEffect } from 'react';
import './DeviceDetails.css';

const DeviceDetails = ({ modelDetails }) => {
  const [mainImage, setMainImage] = useState(null);

  useEffect(() => {
    if (modelDetails && modelDetails.images) {
      setMainImage(modelDetails.images[0]);
    }
  }, [modelDetails]);

  if (!modelDetails) {
    return <div>Bir cihaz seçiniz...</div>;
  }

  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };

  return (
    <div className="image-container">
      <div className="main-image">
        {mainImage && <img src={mainImage} alt={modelDetails.model} />}
      </div>
      <div className="thumbnail-images">
        {modelDetails.images && modelDetails.images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`${modelDetails.model} thumbnail ${index}`}
            className={`thumbnail-image ${img === mainImage ? 'selected' : ''}`}
            onClick={() => handleThumbnailClick(img)}
            style={{ pointerEvents: img === mainImage ? 'none' : 'auto' }}
          />
        ))}
      </div>
    </div>
  );
};

export default DeviceDetails;