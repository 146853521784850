import React, { useState, useEffect, useRef } from 'react';
import './Accordion.css';

const Accordion = ({ deviceType, onModelSelect }) => {
    const [accordionItems, setAccordionItems] = useState([]);
    const accordionContainerRef = useRef(null);

    useEffect(() => {
        fetch('/device-models.json')
            .then(response => response.json())
            .then(jsonData => {
                if (!jsonData.hasOwnProperty(deviceType)) {
                    console.error('Geçersiz cihaz türü:', deviceType);
                    return;
                }
                setAccordionItems(Object.keys(jsonData[deviceType]).map(brand => ({
                    brand,
                    models: jsonData[deviceType][brand].map(model => ({
                        ...model,
                        details: model
                    }))
                })));
            })
            .catch(error => console.error('JSON dosyası yüklenirken bir hata oluştu:', error));
    }, [deviceType]);

    return (
        <div className="accordion_main" id="style-1" ref={accordionContainerRef}>
            <div className="accordion">
                {accordionItems.map(item => (
                    <AccordionItem
                        key={item.brand}
                        brand={item.brand}
                        models={item.models}
                        onModelSelect={onModelSelect}
                    />
                ))}
            </div>
        </div>
    );
};

const AccordionItem = ({ brand, models, onModelSelect }) => {
    const [isActive, setIsActive] = useState(false);

    const toggleAccordion = () => {
        setIsActive(!isActive);
    };

    return (
        <div className="accordion-item">
            <div className={`accordion-item-header ${isActive ? 'active' : ''}`} onClick={toggleAccordion}>
                {brand}
            </div>
            <div className="tracker"></div>
            <div className={`accordion-item-body ${isActive ? 'active' : ''}`}>
                <AccordionItemBodyContent models={models} onModelSelect={onModelSelect} />
            </div>
        </div>
    );
};

const AccordionItemBodyContent = ({ models, onModelSelect }) => {
    useEffect(() => {
        observeStickyHeaders();
    }, []);

    return (
        <div className="accordion-item-body-content">
            <ul>
                {models.map(model => (
                    <li 
                        key={model.details.model_id} 
                        data-brand={model.details.brand} 
                        data-id={model.details.model_id} 
                        onClick={() => onModelSelect(model.details)}
                    >
                        {model.details.model}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const observeStickyHeaders = () => {
    const trackers = document.querySelectorAll('.tracker');
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const header = entry.target.previousElementSibling;
            if (entry.intersectionRatio < 1 && header.classList.contains("active")) {
                header.classList.add("is-sticky");
            } else {
                header.classList.remove("is-sticky");
            }
        });
    }, { threshold: [1] });
    trackers.forEach(tracker => {
        observer.observe(tracker);
    });
};

export default Accordion;
