class Validator {
    constructor() {
        if (this.constructor === Validator) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    validate() {
        throw new Error("Method 'validate()' must be implemented.");
    }

    // Gelişmiş setError fonksiyonu
    setError(inputElement, message, options = {}) {
        const defaultOptions = {
            borderColor: 'border-red-500',
            showIcon: false,
            iconClass: 'error-icon', // İkon için kullanılacak CSS sınıfı
        };

        const settings = { ...defaultOptions, ...options };

        inputElement.classList.add(settings.borderColor);

        if (settings.showIcon) {
            let icon = inputElement.parentElement.querySelector(`.${settings.iconClass}`);
            if (!icon) {
                icon = document.createElement('span');
                icon.classList.add(settings.iconClass);
                icon.textContent = '⚠️';
                inputElement.parentElement.appendChild(icon);
            }
        }

        return message;
    }

    clearError(inputElement, options = {}) {
        const defaultOptions = {
            borderColor: 'border-red-500',
            iconClass: 'error-icon',
        };
        const settings = { ...defaultOptions, ...options };

        inputElement.classList.remove(settings.borderColor);

        const icon = inputElement.parentElement.querySelector(`.${settings.iconClass}`);
        if (icon) {
            icon.remove();
        }
    }
}

export default Validator;  