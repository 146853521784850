import React, { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import axios from "axios";
import { useUser } from "../components/UserContext";
import researchIcon from "../assets/research.png"; // research.png dosyasının yolunu belirtin

const DataTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { user } = useUser(); // Yeni yapı: user nesnesine erişim
  console.log("Current userId:", user.userId);

  // Verileri API'den çekiyoruz
  useEffect(() => {
    const fetchData = async () => {
      if (user.userId) { // userId kontrolü
        try {
          const response = await axios.get(`/api/kayitli-cihazlar/${user.userId}`);
          setData(response.data);
          setFilteredData(response.data); // İlk veri yüklendiğinde tüm veriyi filtrelenmiş veri olarak ayarla
        } catch (error) {
          console.error("Veri alınamadı:", error);
        }
      }
    };
    fetchData();
  }, [user.userId]);

  // Tabloda gösterilecek kolon yapısını tanımlıyoruz
  const columns = React.useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Marka", accessor: "marka" },
      { Header: "Model", accessor: "model" },
      { Header: "Müşteri", accessor: "müşteri" },
      {
        Header: "Kayıt Tarihi",
        accessor: "kayıt_tarihi",
        Cell: ({ value }) => value ? new Date(value).toLocaleDateString("tr-TR") : ""
      },
      { Header: "Fiyat", accessor: "fiyat" },
      { Header: "Talepler", accessor: "talepler" },
      {
        Header: "", // Buton sütunu başlığı boş bırakıldı
        accessor: "button",
        Cell: ({ row }) => (
          <button
            onClick={() => handleButtonClick(row.original)}
            className="focus:outline-none"
          >
            <img
              src={researchIcon}
              alt="Research"
              className="w-8 h-8 rounded-full" // Yuvarlak şekil için rounded-full eklendi
            />
          </button>
        ),
      },
    ],
    []
  );

  // Buton için işlem
  const handleButtonClick = (rowData) => {
    console.log("Button clicked for row:", rowData);
    // İlgili işlemler burada yapılabilir.
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  // Sayfa başına gösterilecek kayıt sayısını güncelleme
  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
  };

  // Arama işlemi
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    const filtered = data.filter(row => 
      Object.values(row).some(cell => 
        cell && cell.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  return (
    <div className="container mx-auto px-2">
      <h1 className="text-2xl font-bold text-indigo-500 text-center mb-6">
        Kullanıcıya Ait Kayıtlı Cihazlar
      </h1>
      <div className="p-4 rounded-lg shadow bg-white">
        {data.length === 0 || columns.length === 0 ? (
          <p>Veri yükleniyor veya veri bulunamadı...</p>
        ) : (
          <>
            {/* Kayıt sayısı seçme dropdown ve arama kutusu */}
            <div className="flex justify-between mb-4">
              <div className="text-sm text-gray-700">
                <label htmlFor="pageSize" className="mr-2">
                  Sayfa başına göster:
                </label>
                <select
                  id="pageSize"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  className="border border-gray-300 rounded-md text-sm px-2 py-1"
                >
                  <option value={10}>10 kayıt</option>
                  <option value={25}>25 kayıt</option>
                  <option value={50}>50 kayıt</option>
                </select>
              </div>
              <div>
                <input
                  type="text"
                  value={searchInput}
                  onChange={handleSearch}
                  placeholder="Ara..."
                  className="border border-gray-300 rounded-md text-sm px-2 py-1"
                />
              </div>
            </div>

            <table {...getTableProps()} className="min-w-full table-auto mb-4">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="bg-blue-100">
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        className={`px-4 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider ${
                          index === 0
                            ? "rounded-tl-lg"
                            : index === headerGroup.headers.length - 1
                            ? "rounded-tr-lg"
                            : ""
                        }`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="hover:bg-gray-50">
                      {row.cells.map(cell => (
                        <td
                          {...cell.getCellProps()}
                          className="px-4 py-2 border-b border-gray-200 text-sm text-gray-700"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* Sayfalandırma Kontrolleri */}
            <div className="flex justify-between items-center mt-4">
              <div className="text-sm text-gray-700">
                {pageIndex * pageSize + 1} - {Math.min((pageIndex + 1) * pageSize, filteredData.length)} arası gösteriliyor, toplam {filteredData.length} kayıt
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-600 hover:bg-gray-100 disabled:opacity-50"
                >
                  Önceki
                </button>
                {pageOptions.map((option, i) => (
                  <button
                    key={i}
                    onClick={() => gotoPage(option)}
                    className={`px-3 py-1 border border-gray-300 rounded-md text-sm ${
                      option === pageIndex ? "bg-indigo-500 text-white" : "text-gray-600"
                    }`}
                  >
                    {option + 1}
                  </button>
                ))}
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-600 hover:bg-gray-100 disabled:opacity-50"
                >
                  Sonraki
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DataTable;