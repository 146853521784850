// UserContext.js
import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    userId: null,
    işletmeAdı: null, // İşletme adı
    // İleride eklenebilecek başka bilgiler
  });

  const clearUser = () => setUser({
    userId: null,
    işletmeAdı: null,
    // Diğer bilgiler de sıfırlanır
  });

  const updateUser = (userData) => setUser(prev => ({ ...prev, ...userData }));

  return (
    <UserContext.Provider value={{ user, setUser: updateUser, clearUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);