// Popup.js
import React from 'react';

const Popup = ({ message, onClose }) => {
  const popupWidth = 420;
  const popupHeight = 190;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div
        style={{ width: `${popupWidth}px`, height: `${popupHeight}px` }}
        className="bg-white rounded-lg shadow-lg flex flex-col items-center justify-center p-4"
      >
        <p className="text-lg font-semibold mb-4">{message}</p>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
        >
          Tamam
        </button>
      </div>
    </div>
  );
};

export default Popup;