import Validator from './Validator';

class RecaptchaValidator extends Validator {
  async validate(token) {
    if (!token) {
      return ['Lütfen reCAPTCHA doğrulamasını tamamlayınız.'];
    }

    const verification = await this.checkRecaptchaToken(token);

    if (!verification.success) {
      return ['reCAPTCHA doğrulaması başarısız.'];
    }

    return []; // Başarılıysa boş bir hata dizisi döndür
  }

  async checkRecaptchaToken(token) {
    try {
      const response = await fetch('/api/verify-recaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      return data; // `data.success` başarılıysa `true` döner
    } catch (error) {
      console.error('reCAPTCHA doğrulama hatası:', error);
      return { success: false, message: 'reCAPTCHA doğrulama başarısız.' };
    }
  }
}

export default RecaptchaValidator;